import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

const authSettings = (): UserManagerSettings => {
  if (typeof window !== "undefined") {
    return {
      authority: process.env.NEXT_PUBLIC_IDENTITY_API_URL as string,
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID as string,
      redirect_uri: process.env.NEXT_PUBLIC_IDENTITY_REDIRECT_URL as string,
      silent_redirect_uri: process.env.NEXT_PUBLIC_IDENTITY_SILENT_REDIRECT_URL,
      post_logout_redirect_uri: process.env.NEXT_PUBLIC_IDENTITY_LOGOUT_REDIRECT_URL,
      popup_redirect_uri: process.env.NEXT_PUBLIC_IDENTITY_SILENT_REDIRECT_URL,
      response_type: 'code',
      scope: process.env.NEXT_PUBLIC_IDENTITY_SCOPE,
      automaticSilentRenew: true,
      userStore: new WebStorageStateStore({
        store: localStorage,
      }),
      silentRequestTimeoutInSeconds: 10, // 10 seconds
      // checkSessionIntervalInSeconds: 60 * 60, // 1 hour
    };
  }
  return {
    authority: process.env.NEXT_PUBLIC_IDENTITY_API_URL as string,
    client_id: process.env.NEXT_PUBLIC_CLIENT_ID as string,
    redirect_uri: process.env.NEXT_PUBLIC_IDENTITY_REDIRECT_URL as string,
    silent_redirect_uri: process.env.NEXT_PUBLIC_IDENTITY_SILENT_REDIRECT_URL,
    post_logout_redirect_uri: process.env.NEXT_PUBLIC_IDENTITY_LOGOUT_REDIRECT_URL,
    response_type: 'code',
    scope: process.env.NEXT_PUBLIC_IDENTITY_SCOPE,
    automaticSilentRenew: true,
    silentRequestTimeoutInSeconds: 10, // 10 seconds
    // checkSessionIntervalInSeconds: 60 * 60, // 1 hour
  };
}

export default authSettings;
