import { toast } from 'react-toastify'

export enum EToastID {
  UPDATE_PROFILE,
  COPY,
  LINK_WALLET_ERROR,
  LINK_WALLET_SUCCESS,
  SEND_REWARD_ERROR,
  SEND_REWARD_SUCCESS,
  GET_GAMES_LIST_ERROR,
}

export enum EToastType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  DEFAULT = 'default',
  WARNING = 'warning',
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const notify = (
  toastId: EToastID | string,
  isPromise: boolean,
  toastType: EToastType = EToastType.INFO,
  message: string,
  autoClose = true,
  // eslint-disable-next-line consistent-return
) => {
  const currentTheme = JSON.parse(localStorage.getItem('toggleTheme') as string)
  switch (isPromise) {
    case true:
      return toast.loading(message, {
        theme: currentTheme === 'dark-theme' ? 'dark' : 'light',
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId,
      })
    case false:
      return toast(message, {
        theme: currentTheme === 'dark-theme' ? 'dark' : 'light',
        position: 'top-right',
        autoClose: autoClose ? 2000 : false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: toastType,
        // progress: undefined,
        // toastId: toastId,
      })
    default:
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const update = (
  toastId: EToastID | string,
  toastType: EToastType,
  message: string,
) =>
  toast.update(toastId, {
    render: message,
    isLoading: false,
    hideProgressBar: false,
    type: toastType,
    autoClose: 2000,
  })
