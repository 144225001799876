import { AxiosRequestHeaders } from 'axios'

import { ApiCaller } from './ApiCaller'

const walletApi = new ApiCaller({
  baseURL: process.env.WALLET_API_URL,
})

export const marketplaceApi = new ApiCaller({
  baseURL: process.env.MARKETPLACE_API_URL,
})

const marketplaceDevApi = new ApiCaller({
  baseURL: process.env.MARKETPLACE_DEV_API_URL,
})

export const lendingApi = new ApiCaller({
  baseURL: process.env.LENDING_API_URL,
})

export const elrondApi = new ApiCaller({
  baseURL: process.env.NEXT_PUBLIC_ELROND_API,
})

export const privateElrondApi = new ApiCaller({
  baseURL: process.env.ELROND_API,
})

export const identityApi = new ApiCaller({
  baseURL: process.env.NEXT_PUBLIC_IDENTITY_API_URL,
})

export const coreApi = new ApiCaller({
  baseURL: process.env.CORE_URL,
})

export const gameApi = new ApiCaller({
  baseURL: process.env.NEXT_PUBLIC_GAME_API_URL,
})

export const metadataApi = new ApiCaller({
  baseURL: process.env.METADATA_API_URL,
})

export const cantinaMetadataApi = new ApiCaller({
  baseURL: process.env.CANTINA_METADATA_API_URL,
})

export const api = new ApiCaller()

export const fetcher = async (url: string) => api.get(url).then(res => res.data)

export const fetcherWithAuth = async (url: string, token: string) => {
  api.setHeader('Authorization', token)
  return api.get(url).then(res => res.data)
}

export const extFetcher = (headers: AxiosRequestHeaders) => async (url: string) =>
  api
    .setHeaders(headers)
    .get(url)
    .then(res => res.data)
