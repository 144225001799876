import "reflect-metadata";
import "../styles/globals.css";
import { Provider } from "react-redux";
import { store } from "@/redux/store";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import "swiper/css/bundle";
import "swiper/css";
import "../public/scss/style.scss";
import { SWRConfig } from "swr";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeContextProvider } from "@/components/Providers/ThemeContext/ThemeContext";
import { IAuthToken } from "@/types/auth";
import authService from "@/service/auth-service";
import { setUser, IUserState, clearUser } from "@/redux/reducers/userSlice";
import { UserContext } from "@/components/Providers/UserContext/UserContext";
import dynamic from "next/dynamic";
import {
    networkConfig,
    chainType,
    walletConnectV2ProjectId,
} from "@/some-config/network";

const SignTransactionsModals = dynamic(
    async () => {
      return (await import("@multiversx/sdk-dapp/UI")).SignTransactionsModals;
    },
    { ssr: false }
);

const DappProvider = dynamic(
    async () => {
      return (await import("@multiversx/sdk-dapp/wrappers")).DappProvider;
    },
    { ssr: false }
);

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  // Constants
  const cfg = networkConfig[chainType]
  const router = useRouter();
  const [ctxUser, setCtxUser] = React.useState<IUserState | null>(null);
  const fillStore = (token?: IAuthToken | null) => {
    if (!token) return;

    // Create user object for redux store
    const user: IUserState = {
      userName: token.profile.userName as string,
      user_id: token.profile.user_id as string,
      email: token.profile.email as string,
      avatar: token.profile.avatar as string,
      wallet: token.profile.wallet as string,
      access_token: token.access_token as string,
      id_token: token.id_token as string,
      expires_at: token.expires_at as number
    };
    // Set user to ctx
    setCtxUser(user);
    // Set user in redux store
    store.dispatch(setUser(user));
  };

  const removeStore = () => {
    // Clear user in redux store
    store.dispatch(clearUser());
    // Clear user in ctx
    setCtxUser(null);
  };

  useEffect(() => {
    authService.userManager.getUser().then(token => {
      // Call fill redux store with user data
      fillStore(token);
    });

    authService.userManager.events.addUserUnloaded(() => {
      removeStore();
    });

    authService.userManager.events.addUserLoaded(async token => {
      fillStore(token);
    });

    authService.userManager.events.addSilentRenewError(() => {
      // console.log("Silent renew error");
      removeStore();
      // TODO popup
      // alert('session expired');
      authService.removeUser();
    });
  }, []);

  useEffect(() => {
    if (pageProps.protected && !ctxUser) {
      authService.userManager.getUser().then(token => {
        // Call fill redux store with user data
        fillStore(token);
        if (!token?.access_token) {
          router.push("/login");
        }
      });
    }
  }, [router, ctxUser]);

  // if (pageProps.protected && !ctxUser) {
  //   return <FullPageLoader />;
  // }

  const userValues = {
    user: ctxUser,
    setUser: setCtxUser
  };

  return (
    <DappProvider
        environment={cfg.id}
        customNetworkConfig={{
            name: cfg.name,
            apiTimeout: cfg.apiTimeout,
            walletConnectV2ProjectId: walletConnectV2ProjectId
        }}
        dappConfig={{
            shouldUseWebViewProvider: true,
            logoutRoute: '/auth/logout-callback'
        }}
        customComponents={{
            transactionTracker: {
                // uncomment this to use the custom transaction tracker
                // component: TransactionsTracker,
                props: {
                    onSuccess: (sessionId: string) => {
                        console.log(`Session ${sessionId} successfully completed`);
                    },
                    onFail: (sessionId: string, errorMessage: string) => {
                        console.error(`Session ${sessionId} failed. ${errorMessage ?? ''}`);
                    }
                }
            }
        }}
    >
      <Provider store={store}>
        <SignTransactionsModals className="sign-tx-modal"/>
        <UserContext.Provider value={userValues}>
          <SWRConfig
            value={{
              refreshInterval: 0
              // fetcher: (resource, init) => fetch(resource, init).then(res => res.json())
            }}
          >
            <ThemeContextProvider>
              <ToastContainer />
              <Component {...pageProps} />
            </ThemeContextProvider>
          </SWRConfig>
        </UserContext.Provider>
      </Provider>
    </DappProvider>
  );
}

export default MyApp;
