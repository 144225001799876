/* eslint-disable */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface IWallet {
  isLoading: boolean
  isLinked: boolean
  data: any
}
const initialState: IWallet = {
  isLoading: false,
  isLinked: false,
  data: null,
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWalletLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setWallet: (state, action: PayloadAction<any>) => {
      state.data = action.payload
      state.isLoading = false
      state.isLinked = true
    },
    setWalletUnlinked: state => {
      state.data = null
      state.isLoading = false
      state.isLinked = false
      localStorage.removeItem('ext_account')
      localStorage.removeItem('ext_login')
    },
  },
})

export const { setWalletLoading, setWallet, setWalletUnlinked } = walletSlice.actions
export default walletSlice.reducer
