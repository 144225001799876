import { useUser } from '@/components/Providers/UserContext/UserContext'
import { ThemeContext } from '@/hooks/useTheme'
import { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
type Props = {
  children: ReactNode
}
export enum EThemeType {
  LIGHT = 'light-theme',
  DARK = 'dark-theme',
}

export function ThemeContextProvider({ children }: Props) {
  const [theme, setThemeValue] = useState('')
  const { user } = useUser()

  const dispatch = useDispatch()

  useEffect(() => {}, [user?.access_token, dispatch])

  useEffect(() => {
    if (window) {
      const currentTheme = JSON.parse(localStorage.getItem('toggleTheme') as string)

      if (currentTheme) {
        setTheme(currentTheme)
      } else {
        const browserTheme = window.matchMedia('(prefers-color-scheme: dark)').matches
          ? EThemeType.DARK
          : EThemeType.LIGHT
        setTheme(browserTheme)
      }
    }
  }, [])

  const setTheme = (theme: string) => {
    localStorage.setItem('toggleTheme', JSON.stringify(theme))
    const root = document.documentElement
    if (theme === EThemeType.DARK) {
      // TODO think how to deal with multilpe set property
      root?.style.setProperty('--bg-theme-color', '#1d1933')
      root?.style.setProperty('--text-theme-color', '#FFFFFF')
      document.body.classList.add(EThemeType.DARK)
    } else {
      root?.style.setProperty('--bg-theme-color', '#ffffff')
      root?.style.setProperty('--text-theme-color', '#464343')
      document.body.classList.remove(EThemeType.DARK)
    }
    setThemeValue(theme)
  }

  const value = {
    theme,
    setTheme,
  }

  return (
    <>
      <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
    </>
  )
}
