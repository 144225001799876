export enum EActionBtn {
  Default,
  Edit,
  Save,
  Cancel,
  Lend,
  Withdraw,
  RewardsData,
  Borrowed,
}

type TActionBtnData = {
  [key: number]: {
    buttonText: string
  }
}

export const ActionBtnData: TActionBtnData = {
  [EActionBtn.Lend]: {
    buttonText: 'Stake',
  },
  [EActionBtn.Withdraw]: {
    buttonText: 'Withdraw',
  },
  [EActionBtn.Borrowed]: {
    buttonText: 'Borrowed',
  },
}

export enum ETabs {
  WALLET_NFTS,
  LENDING_NFTS,
  NFTS,
  SFTS,
}

export type THandleEditForm = (
  action: EActionBtn,
  setIsDisabled: React.Dispatch<React.SetStateAction<any>>,
  setButtonText: React.Dispatch<React.SetStateAction<any>>,
  errors: any,
) => void

export type TLendUserDetails = {
  userName: string
  wallet: string
  userId: string
}
