/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { flattenToAxiosJsonApiQuery } from "./flatten-query";
// import {refreshTokenByAccessToken} from "@/utils/crutches/token-worker";

// import authService from './auth-service';

enum EMethods {
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  GET = "GET",
  DELETE = "DELETE",
}

declare function encodeURIComponent(uriComponent: any): string;

interface IConstructorOptions {
  baseURL?: string;
}

interface ICallOptions {
  query?:
    | {
        [key: string]: boolean | string | number;
      }
    | Record<string, never>;
  data?: any;
  method: EMethods;
  endpoint: string;
  isEmptyBodyAllowed?: boolean;
}

export class ApiCaller {
  private _axios: AxiosInstance;
  private _baseURL?: string;
  private _withCredentials = false;
  private _401interceptor: any;

  constructor(options: IConstructorOptions = {}) {
    this._axios = axios.create();
    if (options.baseURL) {
      this.useBaseURL(options.baseURL);
    }
    this.mount401Interceptor();
  }

  useWithCredentials(cred: boolean) {
    this._withCredentials = cred;
  }

  useBaseURL(baseURL: string) {
    this._baseURL = baseURL;
    return this;
  }

  setAuthorizationHeader(token: string, type = "Bearer ") {
    this._axios.defaults.headers.common.Authorization = `${type}${token}`;
  }

  setHeader(key: string, value: string) {
    if (!key) return;
    this._axios.defaults.headers.common[key] = value;
  }

  setHeaders(headres: AxiosRequestHeaders) {
    Object.entries(headres).forEach(([k, v]) => {
      this._axios.defaults.headers.common[k] = v;
    });
    return this;
  }

  unsetAuthorizationHeader() {
    this._axios.defaults.headers.common.Authorization = "";
  }

  get<T = any>(endpoint: string, query?: any): Promise<AxiosResponse<T>> {
    return this._call({
      method: EMethods.GET,
      endpoint,
      query,
      isEmptyBodyAllowed: true,
    });
  }

  post(endpoint: string, data: any): Promise<AxiosResponse> {
    return this._call({
      method: EMethods.POST,
      endpoint,
      data,
    });
  }

  patch(endpoint: string, data: any) {
    return this._call({
      method: EMethods.PATCH,
      endpoint,
      data,
    });
  }

  put(endpoint: string, data: any) {
    return this._call({
      method: EMethods.PUT,
      endpoint,
      data,
    });
  }

  delete(endpoint: string, data: any) {
    return this._call({
      method: EMethods.DELETE,
      endpoint,
      data,
      isEmptyBodyAllowed: true,
    });
  }

  async _call<T = any>(options: ICallOptions): Promise<AxiosResponse<T>> {
    let config: AxiosRequestConfig = {
      baseURL: this._baseURL,
      params: options.query || {},
      paramsSerializer: function (params: AxiosRequestConfig<any>["params"]) {
        return Object.entries(params)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join("&");
      },
      data: options.isEmptyBodyAllowed && !options.data ? undefined : options.data || {},
      method: options.method,
      url: options.endpoint,
      withCredentials: this._withCredentials,
    };

    try {
      const axiosConfig = await flattenToAxiosJsonApiQuery(config);
      const data = await this._axios<T>(axiosConfig);
      return data;
    } catch (e) {
      // TODO: create error handler
      // eslint-disable-next-line no-console
      // console.dir(JSON.stringify(e));
      throw e;
    }
  }

  mount401Interceptor() {
    this._401interceptor = this._axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        // if (+error.request.status === 401) {
        //     try {
        //         let oldAccessToken = this._axios.defaults.headers.common.Authorization as string;
        //         if (oldAccessToken) {
        //             oldAccessToken = oldAccessToken.replace(/Bearer\s/, "")
        //         }
        //         // Refresh the access token
        //         const token = await refreshTokenByAccessToken(oldAccessToken)
        //         this._axios.defaults.headers.common.Authorization = `Bearer ${token?.access_token}`;
        //         // Retry the original request
        //         const {method, url, data} = error.config;
        //         return this._call({
        //             method,
        //             endpoint: url,
        //             data,
        //         });
        //     } catch (e) {
        //         // Refresh has failed - reject the original request
        //         throw error;
        //     }
        // }

        // If error was not 401 just reject as is
        throw error;
      },
    );
  }

  unmount401Interceptor() {
    this._axios.interceptors.response.eject(this._401interceptor);
  }
}
