import { createContext, useContext } from "react";
import { IUserState } from "@/redux/reducers/userSlice";

type UserContextType = {
  user: IUserState | null;
  setUser: (user: IUserState | null) => void;
};
const UserContextDefaultValues: UserContextType = {
  user: null,
  setUser: () => null
};

export const UserContext = createContext<UserContextType>(UserContextDefaultValues);

export const useUser = () => {
  return useContext(UserContext);
};
