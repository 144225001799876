export enum LoginMethodsEnum {
  ledger = 'ledger',
  walletconnect = 'walletconnect',
  wallet = 'wallet',
  extension = 'extension',
  extra = 'extra',
  walletconnectv2 = 'walletconnectv2',
  none = '',
}

export enum EActionType {
  DEFAULT,
  WITHDRAW,
  DONATE,
}

export enum ImageLoadingType {
  blur = 'blur',
  empty = 'empty',
}
