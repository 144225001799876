import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface IErrorMessage {
  title: string;
  message: string;
  isVisable?: boolean;
}

const initialState: IErrorMessage = {
  title: "",
  message: "",
  isVisable: false,
};

const errorMessageSlice = createSlice({
  name: "errorMessage",
  initialState,
  reducers: {
    showErrorMessage: (state, action: PayloadAction<IErrorMessage>) => {
      state.isVisable = true;
      state.message = action.payload.message
      state.title = action.payload.title

    },
    clearErrorMessage: (state) => {
      state.isVisable = false;
      state.message = "";
      state.title = "";
    }
  },
});

export const { showErrorMessage, clearErrorMessage } = errorMessageSlice.actions;
export default errorMessageSlice.reducer;
