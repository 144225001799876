import { TUserUpdate } from '@/pages/api/profile/update-profile'
import authService from '@/service/auth-service'
import { AccountGameStatus } from '@/types/api-response-types'
import { IUser } from '@/types/auth'
import { EToastID, EToastType, notify, update } from '@/utils/notificationsCenter'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { User } from 'oidc-client-ts'

type TFetchUser = {
  isFetching: boolean
  isError: boolean
  isSuccess: boolean
  error: string | null
}

export type IUserState = IUser & {
  expires_at: number
  access_token: string
  id_token: string
  status?: AccountGameStatus
}

const initialState: IUserState & TFetchUser = {
  userName: '',
  user_id: '',
  email: '',
  avatar: '',
  country: '',
  wallet: '',
  access_token: '',
  id_token: '',
  expires_at: 0,
  isFetching: false,
  isError: false,
  isSuccess: false,
  error: null,
  status: AccountGameStatus.default,
}

export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (user: TUserUpdate, thunkAPI) => {
    try {
      console.log({ user })

      const state: any = thunkAPI.getState()

      console.log({ state })

      const url = '/api/profile/update-profile'
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: state.user.access_token,
        },
        body: JSON.stringify(user),
      })

      const data = await res.json()
      if (res.status === 200) {
        // Update user session
        const oldUser: User | null = await authService.getUser()
        if (data.userName && oldUser) {
          oldUser.profile.userName = data.userName
        }
        if (data.avatar && oldUser) {
          oldUser.profile.avatar = data.avatar
        }
        await authService.storeUser(oldUser as User)

        return { ...data }
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateNickname: (state, action: PayloadAction<string>) => {
      state.userName = action.payload
    },

    updateStatus: (state, action: PayloadAction<string>) => {
      state.status = Number(action.payload) as AccountGameStatus
    },

    setUser: (state, action: PayloadAction<IUserState>) => {
      state.userName = action.payload.userName
      state.user_id = action.payload.user_id
      state.email = action.payload.email
      state.avatar = action.payload.avatar
      // state.country = action.payload.country;
      state.wallet = action.payload.wallet
      state.access_token = action.payload.access_token
      state.id_token = action.payload.id_token
      state.expires_at = action.payload.expires_at
      state.status = AccountGameStatus.default
    },
    clearFetchState: state => {
      state.isError = false
      state.isSuccess = false
      state.isFetching = false
    },
    clearUser: state => {
      state.userName = ''
      state.user_id = ''
      state.email = ''
      state.avatar = ''
      state.country = ''
      state.wallet = ''
      state.access_token = ''
      state.id_token = ''
      state.expires_at = 0
      state.status = AccountGameStatus.default
    },
  },
  extraReducers: builder => {
    builder.addCase(updateUserProfile.pending, state => {
      state.isFetching = true
      state.isError = false
      state.isSuccess = false

      // Toast with promise
      notify(
        EToastID.UPDATE_PROFILE + state.userName! + state.avatar,
        true,
        EToastType.DEFAULT,
        `Updating Profile`,
      )
    })

    builder.addCase(updateUserProfile.fulfilled, (state, { payload }: any) => {
      state.isFetching = false
      state.isError = false
      state.isSuccess = true

      // Update toast success
      update(
        EToastID.UPDATE_PROFILE + state.userName! + state.avatar,
        EToastType.SUCCESS,
        payload.msg,
      )

      if (payload.userName) {
        state.userName = payload.userName
      }
      if (payload.avatar) {
        state.avatar = payload.avatar
      }
    })

    builder.addCase(updateUserProfile.rejected, (state, { payload }: any) => {
      state.isFetching = false
      state.isError = true
      state.isSuccess = false

      // Update toast error
      update(
        EToastID.UPDATE_PROFILE + state.userName! + state.avatar,
        EToastType.ERROR,
        payload.error || 'Something went wrong try again',
      )
    })
  },
})

export const { setUser, clearUser, clearFetchState, updateNickname, updateStatus } =
  userSlice.actions
export default userSlice.reducer
