import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ETabs, TLendUserDetails } from "@/types/app-types";
import moment from "moment/moment";
import { elrondApi } from "@/utils/api/api";

interface IGlobals {
  walletTab: ETabs;
  dappLoginModalVisible: boolean;
  lendModalVisible: boolean;
  lendModalStates: {
    step: number;
    lendOption: "direct" | "public" | null;
    selectedDate: number;
    selectedUser: TLendUserDetails | null;
    revShare: number | null;
    // selectedTime: string;
  };
  allowedLendingCollections: {
    data: string[];
    isFetching: boolean;
  };
}

const initialState: IGlobals = {
  walletTab: ETabs.WALLET_NFTS,
  dappLoginModalVisible: false,
  lendModalVisible: false,
  lendModalStates: {
    step: 0,
    lendOption: null,
    selectedDate: 0,
    selectedUser: null,
    revShare: null,
    // selectedTime: moment.utc().add(24, "hours").toDate().toString(),
  },
  allowedLendingCollections: {
    data: [],
    isFetching: false,
  },
};

export const getAllowedLendingCollections = createAsyncThunk(
  "globals/getAllowedLendingCollections",
  async (_, thunkAPI) => {
    try {
      const res = await elrondApi.post("/query", {
        scAddress: process.env.NEXT_PUBLIC_LENDING_CONTRACT_ADDRESS,
        funcName: "getAllowedTokens",
      });

      return res.data.returnData.map((item: string) =>
        Buffer.from(item, "base64").toString()
      );
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const globalsSlice = createSlice({
  name: "globals",
  initialState,
  reducers: {
    setWalletTab: (state, action: PayloadAction<ETabs>) => {
      state.walletTab = action.payload;
    },
    setDappLoginModalVisible: (state, action: PayloadAction<boolean>) => {
      state.dappLoginModalVisible = action.payload;
    },
    setLendModalVisible: (state, action: PayloadAction<boolean>) => {
      state.lendModalVisible = action.payload;
    },
    setLendModalStep: (state, action: PayloadAction<number>) => {
      state.lendModalStates.step = action.payload;
    },
    setLendModalLendOption: (state, action: PayloadAction<"direct" | "public">) => {
      state.lendModalStates.lendOption = action.payload;
    },
    setLendModalSelectedDate: (state, action: PayloadAction<number>) => {
      state.lendModalStates.selectedDate = action.payload;
    },
    setLendModalSelectedUser: (state, action: PayloadAction<TLendUserDetails | null>) => {
      state.lendModalStates.selectedUser = action.payload;
    },
    resetLendModalSelectedDate: (state) => {
      let nextTime = moment().add(1, "days").toDate();
      const toAdd =
        nextTime.getMinutes() >= 30
          ? 60 - nextTime.getMinutes()
          : 30 - nextTime.getMinutes();
      const roundedTime = moment().add(1, "days").add(toAdd, "minutes").toDate();

      state.lendModalStates.selectedDate = state.lendModalStates.selectedDate
        ? 0
        : roundedTime.getTime();
    },
    setLendModalRevShare: (state, action: PayloadAction<number | null>) => {
      state.lendModalStates.revShare = action.payload;
    },
    resetLendModalStates: (state) => {
      state.lendModalStates = {
        step: 0,
        lendOption: null,
        selectedDate: 0,
        selectedUser: null,
        revShare: null,
        // selectedTime: moment.utc().add(24, "hours").toDate().toString(),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllowedLendingCollections.pending, (state) => {
      state.allowedLendingCollections.isFetching = true;
    });
    builder.addCase(getAllowedLendingCollections.fulfilled, (state, { payload }) => {
      state.allowedLendingCollections.isFetching = false;
      state.allowedLendingCollections.data = payload;
    });
  },
});
export const {
  setWalletTab,
  setDappLoginModalVisible,
  setLendModalVisible,
  setLendModalStep,
  setLendModalLendOption,
  setLendModalSelectedDate,
  setLendModalSelectedUser,
  resetLendModalSelectedDate,
  setLendModalRevShare,
  resetLendModalStates,
} = globalsSlice.actions;
export default globalsSlice.reducer;
