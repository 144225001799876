export interface IPublicUserData {
  id: string;
  userId: string;
  userName: string;
  avatar: string;
  email: string;
  wallet: string;
}

export interface BorrowHistoryDTO {
  id: number;
  startAt: Date;
  endAt: Date;
  borrowerData: IPublicUserData;
}

export interface ApiErrorResponse {
  error: string;
}

export enum AccountGameStatus {
  default,
  web3Blocked,
}

export interface AccountGame {
  userId: string,
  status: AccountGameStatus,
}