/* eslint-disable */
import {SigninRedirectArgs, User, UserManager} from "oidc-client-ts";
import authSettings from "@/utils/auth-settings";
import { store } from "@/redux/store";
import { IUserState, setUser } from "@/redux/reducers/userSlice";

class AuthService {
  _userManager: UserManager;

  constructor() {
    this._userManager = new UserManager(authSettings());
  }

  get userManager() {
    return this._userManager;
  }

  async getUser() {
    return await this._userManager.getUser();
  }

  loginWithPopup() {
    this._userManager.signinPopup();
    return Promise.resolve();
  }

  login(args?: SigninRedirectArgs) {
    return this._userManager.signinRedirect(args);
  }

  async signinPopupCallback(url?: string, callback?: () => void) {
    await this._userManager.signinPopupCallback(url);
    callback?.();
  }

  async signinCallback(url?: string) {
    await this._userManager.signinCallback();
  }

  async signinSilent() {
    return await this._userManager.signinSilent();
  }

  async storeUser(user: User) {
    return this._userManager.storeUser(user);
  }

  async removeUser() {
    return this._userManager.removeUser();
  }

  async signinSilentCallback(url?: string) {
    return await this._userManager.signinSilentCallback(url);
  }

  signout() {
    return this._userManager.signoutRedirect();
  }

  signoutCallback(url?: string) {
    return this._userManager.signoutCallback(url);
  }

  signoutPopupCallback(url?: string, keepOpen?: boolean) {
    return this._userManager.signoutRedirectCallback(url);
  }
}

const authService = new AuthService();

authService.userManager.events.addUserLoaded(async function (user) {
  const reduxUser: IUserState = {
    userName: user.profile.userName as string,
    user_id: user.profile.user_id as string,
    email: user.profile.email as string,
    avatar: user.profile.avatar as string,
    wallet: user.profile.wallet as string,
    access_token: user.access_token as string,
    id_token: user.id_token as string,
    expires_at: user.expires_at as number,
  };
  store.dispatch(setUser(reduxUser));
});
authService.userManager.events.addUserUnloaded(() => {});

authService.userManager.events.addSilentRenewError((err) => {
  // TODO show to user that session is expired.
  console.log("silent renew error", err);
  authService.removeUser();
});
authService.userManager.events.addAccessTokenExpired((args) => {
  console.log("access token expired", args);
  authService
    .signinSilent()
    .then(() => {})
    .catch((err) => {
      console.error("error signing in silent", err);
      authService.removeUser();
    });
});
export default authService;
