import { RootState } from '@/redux/store'
import { EActionType } from '@/types/enums'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export enum ETokenType {
  DEFAULT,
  SFT,
  NFT,
  TOKEN,
}

export interface IUserInput {
  receiver: string
  amount: string
}

export interface ITransferModal {
  visible?: boolean
  step: 'data' | 'summary'
  token: string | null
  amount: string | null
  addressInEditMode: boolean
  decimals: number
  icon: string
  tokenId: string
  nonce?: number
  actionType: EActionType
  tokenType: ETokenType
  userInput: IUserInput
}

const initialState: ITransferModal = {
  visible: false,
  step: 'data',
  token: null,
  amount: null,
  addressInEditMode: false,
  decimals: 0,
  nonce: 0,
  icon: '',
  tokenId: '',
  actionType: EActionType.DEFAULT,
  tokenType: ETokenType.DEFAULT,
  userInput: {
    receiver: '',
    amount: '',
  },
}

const transferModalSlice = createSlice({
  name: 'transferModal',
  initialState,
  reducers: {
    setTransferModalVisible: (state, action: PayloadAction<ITransferModal>) => {
      return (state = { ...state, ...action.payload, visible: true })
    },
    setUserInput: (state, action: PayloadAction<IUserInput>) => {
      return (state = { ...state, userInput: action.payload, step: 'summary' })
    },
    setStep: (state, action: PayloadAction<'data' | 'summary'>) => {
      state.step = action.payload
    },
    setEditMode: state => {
      state.addressInEditMode = true
    },
    setTransferModalClose: state => {
      return (state = { ...initialState })
    },
  },
})

export const transferModal = (state: RootState) => state.transferModal
export const {
  setTransferModalVisible,
  setUserInput,
  setStep,
  setEditMode,
  setTransferModalClose,
} = transferModalSlice.actions
export default transferModalSlice.reducer
