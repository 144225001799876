import { configureStore } from '@reduxjs/toolkit'
import errorMessage from './reducers/errorMessageSlice'
import globalsReducer from './reducers/globalsSlice'
import transferModal from './reducers/transferModalSlice'
import user from './reducers/userSlice'
import wallet from './reducers/walletSlice'

export const store = configureStore({
  reducer: {
    globalsReducer,
    wallet,
    user,
    errorMessage,
    transferModal,
  },
  devTools: process.env.NODE_ENV === 'development',
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
