import React, { useContext } from "react";

type ThemeContextType = {
  theme: string;
  setTheme: (theme: string) => void;
};
const ThemeContextDefaultValues: ThemeContextType = {
  theme: "",
  setTheme: () => {},
};

export const ThemeContext = React.createContext<ThemeContextType>(
  ThemeContextDefaultValues
);

export function useTheme() {
  return useContext(ThemeContext);
}
